/* src/ChessGame.css */

.chess-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .header-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    gap: 20px; /* Add gap between the two sections */
  }
  
  .header-info h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .chessboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .stockfish-info {
    margin-top: 20px;
    text-align: center;
    font-size: 1.1rem;
    color: #444;
    line-height: 1.5;
  }
  
  .make-move-btn {
    padding: 8px 16px;
    margin-left: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .make-move-btn:hover {
    background-color: #45a049;
    transform: translateY(-1px);
  }
  
  .make-move-btn:active {
    background-color: #3e8e41;
    transform: translateY(1px);
  }
  
  .valid-moves-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .move-board {
    position: relative;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .move-board:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .best-move-indicator {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 18px;
    color: gold;
  }
  
  h4 {
    margin: 10px 0;
    font-size: 1.1rem;
    color: #333;
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .chess-game-container {
      padding: 10px;
    }
  
    .header-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .chessboard-container {
      width: 100%;
      max-width: 90vw; /* Use viewport width for better responsiveness */
    }
  
    .valid-moves-container {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .chessboard-container {
      width: 100%;
      max-width: 85vw; /* Further adjust size for smaller screens */
    }
  }